import React from 'react'

import Layout from '../../components/layout'
import FeatureLeft from '../../components/featureLeft'
import { Container, Row, Image, Col } from 'react-bootstrap'
import { Section, SectionGray, SingleSection, SingleSectionGray } from '../../components/sections'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faBalanceScale, faClipboardList, faUserGraduate, faSitemap, faUser, faUserTie, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import coso from '../../images/app/ModCOSO.jpg'

import '../../styles/sigrid/_sci.scss'

const Sci = () => (
<Layout frame='appNav'>

    <Section id='sci'>
        <Container>
            <Row>
                <Col className='text-center heading-section'>
                    <h3>Sistema controllo interno</h3>
                    <p>
                        Lo scopo del SCI è quello di indirizzare l’operato dell’impresa, minimizzando i rischi, verso il raggiungimento degli obiettivi
                        della propria missione aziendale. Il sistema permette di fronteggiare tempestivamente ogni trasformazione dell’ambiente economico,
                        di procedere in tempo utile agli adattamenti necessari per far fronte ai continui cambiamenti, di supportare l’efficienza
                        operativa, di tutelare le attività patrimoniali da possibili perdite, nonché di garantire l’attendibilità dei bilanci e la
                        conformità delle attività alle leggi e ai regolamenti in vigore.
                    </p>
                    <p>
                        Spesso per i sistemi di controllo interno si utilizza il modello di riferimento COSO. Sulla base di questo modello il SCI si
                        compone di cinque elementi fondamentali.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col className='text-center'>
                    {/* <img src={coso} className="img-fluid" style={{margin: '0 auto'}} alt=""></img> */}
                    <Image src={coso} fluid />
                </Col>
            </Row>
        </Container>
    </Section>

    <SectionGray id='ambiente'>
        <Container>
            <Row>
                <Col className='text-center heading-section'>
                    <h3>
                        <FontAwesomeIcon icon={faSquare} /> Ambiente di controllo
                    </h3>
                    <p>
                        È l’elemento chiave in grado di assicurare il migliore governo aziendale possibile, serve a determinare il livello di sensibilità
                        del personale alla necessità di controllo ed è costituito da azioni, politiche e procedure che riflettono l’attitudine generale di
                        tutto il personale in relazione all’importanza del SCI.
                    </p>
                    <p>
                        Quest’ultimo è difatti teso a fornire un ambiente e una struttura aziendale complessivamente idonei alla gestione dei rischi, al
                        perseguimento graduale degli obiettivi e alla necessità di reagire ai continui cambiamenti interni ed esterni.
                    </p>
                    <p>
                        Di seguito è esposto un elenco di fattori che concorrono a definire l'ambiente di controllo.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='alert alert-dark'>

                <FeatureLeft
                  md={4} icon={faBalanceScale} title='Integrità e valori etici' desc="Un ambiente fortemente governato dall'etica a tutti i livelli gerarchici è vitale per l'efficacia delle politiche e dei
                                        sistemi di controllo messi a punto da un'azienda e influisce sui comportamenti che sfuggono ai sistemi di controllo, per
                                        quanto gli stessi siano sofisticati."
                />

                <FeatureLeft
                  md={4} icon={faClipboardList} title='Filosofia di controllo' desc="Comunicazione di standard di comportamento condivisi a tutti i livelli, affinché ci sia una consapevolezza del controllo da
                                parte del management e dei responsabili operativi. Riguarda anche i livelli di rischio accettati, l'atteggiamento verso il
                                reporting, la scelta dei principi e delle stime contabili."
                />

                <FeatureLeft
                  md={4} icon={faUserGraduate} title='Competenza' desc='Il management deve precisare i livelli di competenza richiesti per una particolare mansione e tradurli in termini di
                            conoscenze e capacità.'
                />
            </Row>

            <Row className='alert alert-dark'>

                <FeatureLeft
                  md={3} icon={faSitemap} title='Struttura organizzativa' desc='Serve a definire e pianificare le strategie aziendali, nonché controllare e verificare che vengano raggiunti gli obiettivi.
                                La realizzazione di una struttura adeguata implica la definizione delle principali aree di autorità e di responsabilità,
                                come pure la creazione di adeguate linee gerarchiche.'
                />

                <FeatureLeft
                  md={3} icon={faUser} title='Gestione delle risorse umane' desc="Allocazione ottimale del tempo e delle risorse per la gestione del controllo e dei rischi aziendali. Creazione di un
                            ambiente che promuova un approccio basato sulla comprensione e sull'insegnamento delle problematiche legate ai controlli e
                            ai rischi."
                />

                <FeatureLeft
                  md={3} icon={faUserTie} title='Assegnazione di autorità e responsabilità' desc='Modalità di delega con cui sono assegnate autorità e responsabilità per le attività operative. Si devono anche stabilire le
                                relazioni tra il reporting e i livelli gerarchici..'
                />

                <FeatureLeft
                  md={3} icon={faExternalLinkAlt} title='Organi amministrativi indipendenti' desc='È indispensabile che il CdA sia in parte composto da amministratori non esecutivi, perchè essi possano esaminare le attività
                                    del managment.'
                />
            </Row>

        </Container>
    </SectionGray>

    <SingleSection id='rischio'>
        <Col className='text-center heading-section'>
            <h3><FontAwesomeIcon icon={faSquare} /> Valutazione e gestione del rischio</h3>
            <p>È necessaria in quanto ogni azienda nel suo operato deve affrontare una varietà di rischi sia interni che esterni. Si definisce
                rischio un evento potenziale che riduce la probabilità di raggiungere obiettivi di business o che può implicare l'incertezza di
                ottenere benefici.
            </p>
            <p>
                I fattori di rischio esterni possono essere il progresso tecnologico, la concorrenza, il cambiamento del bisogno e delle attese dei
                clienti, le nuove leggi/regolamentazioni, le catastrofi naturali e i cambiamenti economici. I fattori di rischio interni possono
                essere l'interruzione dei sistemi informatici, la competenza del personale, il cambiamento delle responsabilità del management, la
                natura delle attività svolte e la presenza di un CdA o di un audit committee inefficace.
            </p>
            <p>
                Per il monitoraggio dei rischi sono necessarie procedure di reporting tempestive in relazione a tutti i fatti di rilevanza.
            </p>
        </Col>
    </SingleSection>

    <SectionGray id='controllo'>

        <Container>
            <Row>
                <Col md='12' className='text-center heading-section'>
                    <h3><FontAwesomeIcon icon={faSquare} /> Attività di controllo</h3>
                    <p>
                        Sono l’insieme delle politiche e delle procedure che assicurano al management che le proprie direttive siano applicate. Si attuano
                        in tutti i livelli dell’organizzazione aziendale al fine di agevolare l’adozione di provvedimenti necessari per far fronte ai
                        rischi che potrebbero pregiudicare la realizzazione degli obiettivi aziendali. Di seguito è esposto un elenco di possibili attività
                        di controllo
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row row-bottom-padded-md'>

                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Separazione dei compiti</h3>
                        <p>
                            I compiti vengono ripartiti tra più persone al fine di ridurre il rischio di errori e irregolarità.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Corretta autorizzazione per tutte le operazioni</h3>
                        <p>Si definisce autorizzazioni generali, direttive da seguire per operazioni ricorrenti, o specifiche, direttive per operazioni
                            singole non ricorrenti.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Documentazione e registrazione delle operazioni</h3>
                        <p>Perseguita osservando i principi: prenumerazione consecutiva dei documenti, rilevazione semplice e comprensibile delle
                            operazioni e struttura delle rilevazioni adeguate per agevolarne la compilazione.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Controllo fisico sui beni e registrazioni</h3>
                        <p>Inventari fisici, assicurazioni adeguate, procedure per la rocostruzione dei dati contabili, servizio di vigilanza e elenchi di
                            persone autorizzate.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Controlli indipendenti</h3>
                        <p>
                            Hanno la funzione di mantenere la stabilità del SCI attraverso un'opportuna "pressione" sugli operatori aziendali.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Analisi svolte dall'alta direzione</h3>
                        <p>Le performances realizzate sono analizzate raffrontandole con i budget, le proiezioni, i risultati dei periodi precedenti e con
                            i risultati dei concorrenti.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Gestione delle attività</h3>
                        <p>Tutti i responsabili di funzione o attività procedono all'analisi delle performances.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Elaborazione dei dati</h3>
                        <p>Vengono eseguiti numerosi controlli per verificare l'accuratezza, la completezza e l'autorizzazione delle operazioni. I dati
                            inseriti sono sottoposti a procedure automatiche di controllo (ad esempio controlli di plausibilità) o confrontati con archivi
                            di verifica approvati.
                        </p>
                    </div>
                </Col>
                <Col md='4' xs='12'>
                    <div className='feature-text'>
                        <h3>Indicatori di performance</h3>
                        <p>Costituiscono attività di controllo l'analisi comparata di diversi insiemi di dati, operativi o finanziari, l'esame delle
                            correlazioni e le conseguenti azioni investigative e correttive.
                        </p>
                    </div>
                </Col>

            </Row>
        </Container>
    </SectionGray>

    <SingleSection id='si'>
        <Col className='text-center heading-section'>
            <h3><FontAwesomeIcon icon={faSquare} /> Sistema informativo aziendale</h3>
            <p>
                Deve permettere che le informazioni siano individuate, rilevate e diffuse nei modi e nei tempi appropriati per consentire al
                personale di assolvere le proprie responsabilità.
            </p>
            <p>
                La qualità delle informazioni si valuta in base al contenuto, alla tempestività di reperimento, allo stato di aggiornamento,
                all’accuratezza e all’accessibilità delle stesse. I sistemi di informazione e comunicazione (SIC) consentono al personale la
                raccolta e lo scambio delle informazioni necessarie alla gestione e al controllo.
            </p>
        </Col>
    </SingleSection>

    <SingleSectionGray id='mon'>
        <Col className='text-center heading-section'>
            <h3><FontAwesomeIcon icon={faSquare} /> Il monitoraggio</h3>
            <p>
                Consiste nella verifica continua e/o periodica dell’efficacia delle attività di controllo messe in atto al fine di appurare che
                esse operino secondo gli obiettivi formulati e che siano adeguate rispetto ad eventuali cambiamenti della realtà operativa.
            </p>
            <p>
                All’interno dell’organizzazione dovrebbe essere presente un processo continuo che dia al CdA una ragionevole garanzia
                sull’esistenza di appropriate procedure di controllo e che esse siano applicate.
            </p>
            <p>
                L’identificazione dei cambiamenti nell’impresa e del suo ambiente può richiedere modifiche al SCI. Occorre prevedere un supporto
                adeguato al vertice aziendale per le informazioni relative al sistema di controllo, da diffondere all'esterno.
            </p>
        </Col>
    </SingleSectionGray>

</Layout>

)

export default Sci
